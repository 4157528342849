import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CodeEditor from '@uiw/react-textarea-code-editor';
import Api from "../../../core/api/api";
import { Block, Element, Workflow, c_type, Langs } from "../../../core/api/interfaces";
import i18n from "../../../core/i18n/i18n";

import WorkflowActions from '../../../core/stores/workflows/actions';
import { getLang } from "../../../core/utils";
import Logo from '../../assets/img/logo.svg';
import MilleIsoleLogo from '../../assets/img/milleisole-logo.svg';
import TuskCodeLogo from '../../assets/img/tuskcode-logo.svg';
import { Modal } from "../molecules/Modal";
import './SidebarComponent.scss';

const Elements = ({workflow, block, addNewElement, editElement}: {workflow: Workflow, block: Block, addNewElement: () => void, editElement: (element: Element) => void}) => {
  const dispatch = useDispatch();

  const languages = workflow.supp_langs.split(',');
  const [currentLang, setCurrentLang] = useState(workflow.lang);

  return (
    <>
      <div className="d-flex">
        {languages.map((lang: string, idx: number) => (
          <div
            key={'side-wf-lang-' + idx}
            className={'border px-1' + (lang === currentLang ? ' bg-dark text-white' : '')}
            onClick={() => setCurrentLang(lang)}
          >{lang}</div>
        ))}
      </div>

      {block.elements
        .sort((a, b) => Number(a.e_order) - Number(b.e_order))
        .map((element: Element, idx: number) => (
          <div key={'side-element-' + idx} className="d-flex justify-content-around align-items-center">
            <div className="mx-3">
              <button
                className="p-0 bg-transparent"
                style={{borderWidth: '0 16px 16px 16px', borderColor: 'transparent transparent grey transparent', borderStyle: 'solid'}}
                disabled={element.e_order == '1'}
                onClick={() => {
                  dispatch(WorkflowActions.updateLocalWorkflow({
                    ...workflow,
                    blocks: workflow.blocks.map((b: Block) => {
                      if (b.block_id == block.block_id) {
                        return {
                          ...block,
                          elements: b.elements.map((e) => {
                            const current = element.e_order;
                            const other = '' + (Number(current) - 1);

                            if (e.e_order == current) {
                              return {
                                ...e,
                                e_order: other
                              }
                            }
                            if (e.e_order == other) {
                              return {
                                ...e,
                                e_order: current
                              }
                            }
                            return e;
                          }),
                        }
                      }
                      return b;
                    }),
                  }))
                }}
              />
              {element.e_order}
              <button
                className="p-0 bg-transparent"
                style={{borderWidth: '16px 16px 0 16px', borderColor: 'grey transparent transparent transparent', borderStyle: 'solid'}}
                disabled={element.e_order == '' + block.elements.length}
                onClick={() => {
                  dispatch(WorkflowActions.updateLocalWorkflow({
                    ...workflow,
                    blocks: workflow.blocks.map((b: Block) => {
                      if (b.block_id == block.block_id) {
                        return {
                          ...block,
                          elements: b.elements.map((e) => {
                            const current = element.e_order;
                            const other = '' + (Number(current) + 1);

                            if (e.e_order == current) {
                              return {
                                ...e,
                                e_order: other
                              }
                            }
                            if (e.e_order == other) {
                              return {
                                ...e,
                                e_order: current
                              }
                            }
                            return e;
                          }),
                        }
                      }
                      return b;
                    }),
                  }))
                }}
              />
            </div>
            <div className="flex-grow-1">
              <div className="form-group mb-0 py-1">
                <label onClick={() => editElement(element)}>{element.d_type} <i className="fas fa-fw fa-cog"></i> <small>{element.var_name}</small> </label>
                <input
                  className="form-control"
                  disabled={true}
                  value={(() => {
                    const lang = getLang(element.langs, currentLang)[(element.c_type === c_type.MEDIA || element.c_type === c_type.LINK) ? 'uri' : 'label']
                    if (typeof lang === 'object' && element.c_type === c_type.SELECT) {
                      return 'Array';
                    } else {
                      return lang;
                    }
                  })()} // here
                  // onChange={({target: {value}}: any) => {
                  //   dispatch(WorkflowActions.updateLocalWorkflow({
                  //     ...workflow,
                  //     blocks: workflow.blocks.map((b: any) => {
                  //       if (b.block_id == block.block_id) {
                  //         return {
                  //           ...block,
                  //           elements: b.elements.map((e: any) => {
                  //             if (e.elem_id == element.elem_id) {
                  //               return {
                  //                 ...element,
                  //                 langs: {
                  //                   ...element.langs,
                  //                   [currentLang]: (element.c_type === c_type.MEDIA || element.c_type === c_type.LINK) ? {
                  //                     label: '',
                  //                     uri: value,
                  //                   } : {
                  //                     label: value,
                  //                     uri: '',
                  //                   }
                  //                 }
                  //               }
                  //             }
                  //             return e;
                  //           })
                  //         }
                  //       }
                  //       return b;
                  //     }),
                  //   }))
                  // }}
                />
              </div>
            </div>
            <div className="mx-3">
              <div
                className="rounded-circle bg-dark text-white px-2"
                onClick={() => {
                  window.confirm(i18n.translate('general.sure-delete'))
                  && dispatch(WorkflowActions.updateLocalWorkflow({
                    ...workflow,
                    blocks: workflow.blocks.map((b: any) => {
                      if (b.block_id == block.block_id) {
                        return {
                          ...block,
                          elements: block.elements.filter((ex: any, i: number) => i !== idx),
                        }
                      }
                      return b;
                    }),
                  }))
                }}
              >
                -
              </div>
            </div>
          </div>
        )
      )}
      <div className="px-2 mb-2">
        <button
          className="btn btn-outline-light w-100"
          onClick={() => {
            addNewElement();
          }}
        >{i18n.translate('editor.add-new-element')}</button>
      </div>
    </>
  )
}

const SaveButton = ({onSave}: any) => (
  <button
    className="btn btn-secondary position-absolute"
    onClick={() => onSave()}
    style={{right: '-4px', top: '-8px', padding: '0 4px', zIndex: 1}}
  >
    <i className="fas fa-save"></i>
  </button>
)

const WorkflowSelectedBlockSidebar = ({block, workflow, addNewElement, editElement}: {block: Block, workflow: Workflow, addNewElement: () => void, editElement: (element: Element) => void}) => {
  const dispatch = useDispatch();
  const [blockExec, setBlockExec] = useState(block.exec || '');
  const [blockDescription, setBlockDescription] = useState(block.description || '');
  const [wfList, setWfList] = useState<{name: string, wid: string}[]>([]);

  useEffect(() => {
    setBlockExec(block.exec || '');
    setBlockDescription(block.description || '');

    if (block.type === 'SW') {
      Api.getSubWorkflowList().then(wfl => setWfList(wfl));
    }
  }, [block, workflow])

  useEffect(() => {
    // update exec 250ms after typing
    if (block.exec === blockExec) {
      return;
    }

    const timeout = setTimeout(() => {
      dispatch(WorkflowActions.updateLocalWorkflow({
        ...workflow,
        blocks: workflow.blocks.map((b: any) => {
          if (b.block_id == block.block_id) {
            return {
              ...block,
              exec: blockExec,
            }
          }
          return b;
        }),
      }))
    }, 250);

    return () => {
      clearTimeout(timeout);
    }
  }, [blockExec]);

  useEffect(() => {
    // update description 250ms after typing
    if (block.description === blockDescription) {
      return;
    }

    const timeout = setTimeout(() => {
      dispatch(WorkflowActions.updateLocalWorkflow({
        ...workflow,
        blocks: workflow.blocks.map((b: any) => {
          if (b.block_id == block.block_id) {
            return {
              ...block,
              description: blockDescription,
            }
          }
          return b;
        }),
      }))
    }, 250);

    return () => {
      clearTimeout(timeout);
    }
  }, [blockDescription]);

  return (
    <>
      <SidebarHeading />

      <hr className="sidebar-divider my-0" />

      {(block.type === '' || block.type === null) && (<>
        <div className="form-group p-2">
          <label>{i18n.translate('sidebar.description')}</label>
          <div className="position-relative">
            {/* <SaveButton
              onSave={() => {
                dispatch(WorkflowActions.updateLocalWorkflow({
                  ...workflow,
                  blocks: workflow.blocks.map((b: any) => {
                    if (b.block_id == block.block_id) {
                      return {
                        ...block,
                        description: blockDescription,
                      }
                    }
                    return b;
                  }),
                }))
              }}
            /> */}
            <input
              className="form-control"
              value={blockDescription}
              onChange={(evn) => setBlockDescription(evn.target.value)}
            />
          </div>
        </div>

        <div className="p-2">
          <p>{i18n.translate('sidebar.exec')}:</p>

          <div className="position-relative">
            {/* <SaveButton
              onSave={() => {
                dispatch(WorkflowActions.updateLocalWorkflow({
                  ...workflow,
                  blocks: workflow.blocks.map((b: any) => {
                    if (b.block_id == block.block_id) {
                      return {
                        ...block,
                        exec: blockExec,
                      }
                    }
                    return b;
                  }),
                }))
              }}
            /> */}
            <CodeEditor
              className="w-100 overflow-auto p-1 mt-1"
              value={blockExec}
              language="php"
              placeholder="Please enter PHP code."
              // data-color-mode="dark"
              onChange={(evn: any) => setBlockExec(evn.target.value)}
              style={{
                fontSize: 12,
                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
              }}
            />
            {/* <textarea
              className="w-100 text-white text-monospace p-1 bg-dark overflow-auto mt-1"
              style={{height: block.exec != '' ? '25vh' : '100px'}}
              value={blockExec}
              onChange={({target: {value}}: any) => {
                setBlockExec(value);
              }}
            /> */}
          </div>
        </div>

        {block.block_id != workflow.start_block_id && (
          <div className="p-2">
            <div className="border">
              <Elements workflow={workflow} block={block} addNewElement={addNewElement} editElement={editElement} />
            </div>
          </div>
        )}

          <div className="p-2">
          <p className="mt-2 font-weight-bold">{i18n.translate('general.exits')}</p>
          {block.exits.map((exit, idx: number) => (
            <div key={'side-exit-' + idx}>
              <div className="bg-dark d-inline-block mt-2" style={{width: "85%"}}>
                {(() => {
                  const el = block.elements.find(el => el.c_type === c_type.BUTTON && Number(el.exit_num) === idx)
                  return el ? el.langs[workflow.lang].label : i18n.translate('general.exit') + ' ' + idx
                })()}
              </div>
              {idx > 1 &&
                <span
                  className="text-center d-inline-block"
                  style={{width: "15%"}}
                  onClick={() => {
                    window.confirm(i18n.translate('general.sure-delete'))
                    && dispatch(WorkflowActions.updateLocalWorkflow({
                      ...workflow,
                      blocks: workflow.blocks.map((b: any) => {
                        if (b.block_id == block.block_id) {
                          return {
                            ...block,
                            exits: block.exits.filter((ex: any, i: number) => i !== idx),
                          }
                        }
                        return b;
                      }),
                    }))
                  }}
                >
                  <span className="rounded-circle bg-dark text-white px-2">
                    -
                  </span>
                </span>}
            </div>
          ))}
          <button
            className="btn btn-outline-light mt-2 w-100"
            onClick={() => {
              dispatch(WorkflowActions.updateLocalWorkflow({
                ...workflow,
                blocks: workflow.blocks.map((b: any) => {
                  if (b.block_id == block.block_id) {
                    return {
                      ...block,
                      exits: [
                        ...block.exits,
                        {
                          exit_dir: 0,
                        },
                      ],
                    }
                  }
                  return b;
                }),
              }))
            }}
            >{i18n.translate('general.add-exit')}</button>
        </div>
      </>)}

      {block.type === 'SW' && (
        <div className="form-group p-2">
          <label>{i18n.translate('sidebar.select-process-call')}</label>
          <select
            className="d-block w-100 px-1 form-control"
            value={(() => {
              const tmp = block.exec?.match(/\[.*\]/);
              if (tmp) {
                return wfList.findIndex(({wid}: any) => wid == tmp);
              }
              return -1;
            })()}
            onChange={({target: {value}}) => {
              if (value != '-1') {
                const idx = Number(value);
                dispatch(WorkflowActions.updateLocalWorkflow({
                  ...workflow,
                  blocks: workflow.blocks.map((b: any) => {
                    if (b.block_id == block.block_id) {
                      return {
                        ...block,
                        description: wfList[idx].name,
                        exec: 'wofoEnv->goToFlussu("' + wfList[idx].wid + '");'
                      }
                    }
                    return b;
                  }),
                }))
              }
            }}
          >
            <option disabled value={-1}>{i18n.translate('sidebar.select-workflow')}</option>
            {wfList.map(({wid, name}: any, idx: number) => (
              <option key={`${block.block_id}sub${wid}${idx}${name}`} value={idx}>{name}</option>
            ))}
          </select>
        </div>
      )}

      {block.type === 'NT' && (
        <div className="form-group p-2">
          <label>{i18n.translate('general.note')}</label>
          <textarea
            className="w-100 p-1 overflow-auto mt-1"
            style={{height: block.exec != '' ? '25vh' : '100px'}}
            value={blockDescription}
            onChange={({target: {value}}: any) => {
              setBlockDescription(value);
            }}
          />
        </div>
      )}

      <div className="p-2">
        <small>{i18n.translate('sidebar.block-id')}: {block.block_id}</small>
      </div>
    </>
  )
};

const WorkflowDefaultSidebar = ({workflow, startBlock}: {workflow: Workflow, startBlock: any}) => {
  const dispatch = useDispatch();
  const { prop } = useSelector((state: any) => state.general);

  return (
    <>
      <SidebarHeading />

      <hr className="sidebar-divider my-0" />

      {/* <Link to={`/home/${workflow.wid}/settings`}> */}
      <a href={prop} className="text-white mt-2 d-inline-block">
        <button className="btn btn-link text-white" title={i18n.translate('editor.settings')}>
          <i className="fas fa-fw fa-edit"></i>
        </button>
        {i18n.translate('editor.settings')}
      </a>
      {/* </Link> */}

      <div className="p-2 d-flex justify-content-between">
        <span>{i18n.translate('sidebar.is-active')}:</span>
        <div className="checkbox">
          <input type="checkbox" checked={workflow.is_active === '1'} onChange={({target: {checked}}: any) => {
            dispatch(WorkflowActions.updateLocalWorkflow({
              ...workflow,
              is_active: checked ? '1' : '0',
            }))
          }} />
        </div>
      </div>

      <div className="p-2">
        <p>{i18n.translate('sidebar.exec')}:</p>
        <textarea className="w-100 text-white text-monospace p-1 bg-dark overflow-auto mt-1" style={{height: startBlock.exec != '' ? '25vh' : '100px'}} value={startBlock.exec} onChange={({target: {value}}: any) => {
          dispatch(WorkflowActions.updateLocalWorkflow({
            ...workflow,
            blocks: workflow.blocks.map((block) => {
              if (block.block_id == startBlock.block_id) {
                return {
                  ...startBlock,
                  exec: value,
                }
              }
              return block;
            }),
          }))
        }} />
      </div>

      <div className="p-2">
        <select className="w-100" value={workflow.lang} onChange={({target: {value}}: any) => {
          dispatch(WorkflowActions.updateLocalWorkflow({
            ...workflow,
            lang: value
          }))
        }}>
          {/* <option value="-1" disabled>{i18n.translate('sidebar.select-default-lang')}</option> */}
          {workflow.supp_langs.split(',').map((lang: string, idx: number) => (
            <option key={'side-lang-select-' + idx} value={lang}>{lang}</option>
          ))}
        </select>
      </div>

      <div className="p-2">
        <small>{i18n.translate('sidebar.workflow-id')}: {workflow.wid.replace(/\|.*\]/g, ']')}</small>
      </div>

    </>
  )
}

const Credits = () => (
  <div className="w-100" style={{bottom: 0}}>
    <hr className="sidebar-divider" />
    <li className="nav-item active">
      <div className="nav-link w-100 text-center">
        <div>
          <span className="small">Editor powered by&nbsp;</span>
          <a target="_blank" rel="noopener" href="https://tuskcode.it">
            <img style={{height: '23px', verticalAlign: 'baseline', marginBottom: '-1px'}} src={TuskCodeLogo} alt="tuskcode" />
          </a>
        </div>
        <div className="mt-2">
          <span className="paragraph">for&nbsp;&nbsp;&nbsp;</span>
          <a className="text-white text-decoration-none paragraph" target="_blank" rel="noopener" href="http://milleisole.com">
            <img style={{height: '30px'}} src={MilleIsoleLogo} alt="milleisole" />
            &nbsp;&nbsp;@milleisole.com
          </a>
        </div>
      </div>
    </li>
  </div>
);

const SelectArrayOptions = ({languages, workflow, addElementParams, setAddElementParams}: any) => {
  // languages = ['IT', 'EN'];
  const [currentLang, setCurrentLang] = useState(workflow.lang);

  // @ts-ignore
  const defaultOpts = Object.keys(Object.entries(addElementParams.langs)[0][1].label).map(key => {
    return [
      key + ',0',
      ...(Object.keys(addElementParams.langs).map(lang => {
        return addElementParams.langs[lang].label[key];
      })),
    ]
  })

  const [options, setOptions] = useState(
    defaultOpts.length > 0 ? defaultOpts :
    [
      [',0', ...(languages.map((l: any) => ''))],
    ]
  );

  useEffect(() => {
    const newLangs = languages.reduce((acc: object, lang: string) => ({
      ...acc,
      [lang]: {
        label: Object.fromEntries(
          options.map(([key, ...values]) => {
            return [key, values[languages.indexOf(lang)]]
          })
        ),
      }
    }), {})

    setAddElementParams({
      ...addElementParams,
      langs: {
        ...newLangs
      }
    })
  }, [options])

  return (
    <table className="table table-bordered mb-3" style={{ tableLayout: 'fixed' }}>
      <thead>
        <tr>
          <th colSpan={2}>{i18n.translate('sidebar.codes')}</th>
          <th colSpan={1}>def</th>
          <th colSpan={4}>
            <div className="border border-dark my-2">
              <div className="d-flex">
                {languages.map((lang: string, idx: number) => (
                  <div
                    key={'side-wf-lang-' + idx}
                    className={'border px-1' + (lang === currentLang ? ' bg-dark text-white' : '')}
                    onClick={() => setCurrentLang(lang)}
                  >{lang}</div>
                ))}
              </div>
            </div>
          </th>
          <th colSpan={1}>{i18n.translate('sidebar.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {options.map(([key, ...values], i) => (
          <tr key={`option-${i}`}>
            <td colSpan={2}>
              <input
                className={
                  `w-100 ${
                    (
                      key.split(',')[0] === '' ||
                      options.map(([key]) => key.split(',')[0]).filter((a,idx) => idx !== i).includes(key.split(',')[0])
                    )
                  ? 'border border-3 border-danger'
                  : ''}
                `}
                type="text"
                value={key.split(',')[0]}
                onChange={({target}) => {
                  const [key, isDefault] = options[i][0].split(',');
                  options[i][0] = target.value + ',' + isDefault;
                  setOptions([...options]);
                }}
              />
            </td>
            <td colSpan={1} className="text-center align-middle">
              <div className="checkbox">
                <input type="checkbox" checked={key.split(',')[1] == 1} onChange={({target: {checked}}: any) => {
                  const [key, isDefault] = options[i][0].split(',');
                  const newOptions = options.map(([k, ...rest]) => {
                    return [k.replace(',1', ',0'), ...rest];
                  });
                  newOptions[i][0] = key + ',' + (checked ? '1' : '0');
                  setOptions([...newOptions]);
                }} />
              </div>
            </td>
            <td colSpan={4}>
              <input className="w-100" type="text" value={values[languages.indexOf(currentLang)]} onChange={({target}) => {
                options[i][languages.indexOf(currentLang) + 1] = target.value;
                setOptions([...options]);
              }} />
            </td>
            <td colSpan={1} className="text-center align-middle">
              <a
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#666',
                  color: '#fff',
                  fontWeight: 'bold',
                  padding: '0px 5px',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  setOptions(options.filter((e,j) => j !== i))
                }}
              >
              -
              </a>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={8}>
            <a className="cursor-pointer" onClick={() => {
              options.push(
                [',0', ...(languages.map((l: any) => ''))],
              );
              setOptions([...options]);
            }}>
              + {i18n.translate('sidebar.newrow')}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

const useSidebarResize = (sidebarRef: any) => {
  const [isTracking, setTracking] = useState(false);
  const [startWidth, setStartWidth] = useState<number>(0);
  const [startCursorScreenX, setStartCursorScreenX] = useState<number>(0);

  const target = sidebarRef.current as HTMLElement;

  // TODO: sistemare
  const maxWidth = window.innerWidth / 100 * 75;
  const minWidth = 370; // window.innerWidth / 100 * 25;

  const onMouseRelease = useCallback((e: React.MouseEvent) => {
    setTracking(false);
  }, []);

  const onMouseMove = useCallback((e: React.MouseEvent) => {
    if (isTracking) {
      const cursorScreenXDelta = e.screenX - startCursorScreenX;
      const newWidth = Math.max(
        Math.min(startWidth + cursorScreenXDelta, maxWidth),
        minWidth
      );

      target.style.width = newWidth + 'px';
    }
  }, [isTracking]);

  useEffect(() => {
    const handleDocumentMouseUp = (event: React.MouseEvent) => {
      if (event.button !== 2) {
        onMouseRelease(event);
      }
    };

    const handleDocumentMouseMove = (event: React.MouseEvent) => {
      if (event.button !== 2) {
        onMouseMove(event);
      }
    };

    document.addEventListener('mouseup', handleDocumentMouseUp as any);
    document.addEventListener('mousemove', handleDocumentMouseMove as any);
    return () => {
      document.removeEventListener('mouseup', handleDocumentMouseUp as any);
      document.removeEventListener('mousemove', handleDocumentMouseMove as any);
    };
  }, [onMouseRelease, onMouseMove]);

  return {
    onMouseDown: (e: React.MouseEvent) => {
      if (sidebarRef && sidebarRef.current) {
        e.preventDefault();
        e.stopPropagation();

        setStartWidth(target.clientWidth);
        setStartCursorScreenX(e.screenX);
        setTracking(true);

        console.log('~ tracking started');
      }
    },
    onClick: (e: React.MouseEvent) => {
      if (sidebarRef && sidebarRef.current) {
        e.preventDefault();
        e.stopPropagation();

        target.style.width = '0px';
      }
    }
  }
}

const ElementModal = ({model, languages, block, type, onModalClose, workflow, selectedBlockId}: {model: Element, languages: string[], block: Block, type: 'edit' | 'new', onModalClose: () => void, workflow: Workflow, selectedBlockId: string}) => {
  const dispatch = useDispatch();
  const [addElementParams, setAddElementParams] = useState<Element>(model)

  const [currentLang, setCurrentLang] = useState(workflow.lang)

  return (
    <>
      <Modal>
        <div className="px-3 py-4 text-dark">
          {type === 'new' && (
            <div>
              <p>{i18n.translate('sidebar.type')}</p>
              <select
                className="w-100"
                value={addElementParams.c_type}
                onChange={({target: {value}}: any) => {
                  setAddElementParams({
                    ...addElementParams,
                    c_type: value
                  })
                }}>
                  {/* c_type */}
                  {Object.keys(c_type).filter(key => !isNaN(Number((c_type as any)[key]))).map((el, idx) => (
                    <option key={'side-c_type-' + idx} value={idx}>{el}</option>
                  ))}
              </select>
            </div>
          )}

          {(
            addElementParams.c_type === c_type.INPUT
            || addElementParams.c_type === c_type.BUTTON
            || addElementParams.c_type === c_type.TXT_ASSIGN
            || addElementParams.c_type === c_type.SELECT
            || addElementParams.c_type === c_type.FILE
          ) && (
            <div>
              <p>{i18n.translate('sidebar.var_name')}</p>
              <input
                className="w-100"
                value={addElementParams.var_name}
                onChange={({target: {value}}: any) => {
                  setAddElementParams({
                    ...addElementParams,
                    var_name: value,
                  })
                }}
              />
              {(addElementParams.c_type === c_type.INPUT ||
                addElementParams.c_type === c_type.FILE ||
                addElementParams.c_type === c_type.SELECT) && (
                <>
                  <label className="d-block mt-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      checked={addElementParams.css.display_info.mandatory}
                      onChange={() => {
                        setAddElementParams({
                          ...addElementParams,
                          css: {
                            ...addElementParams.css,
                            display_info: {
                              ...addElementParams.css.display_info,
                              mandatory: !addElementParams.css.display_info.mandatory,
                            }
                          },
                        })
                      }}
                    />
                    {i18n.translate('general.mandatory')}
                  </label>
                </>
              )}
              {addElementParams.c_type === c_type.INPUT && (
                <>
                  <label className="d-block">
                    {i18n.translate('general.subtype')}
                    <select
                      className="form-control"
                      value={addElementParams.css.display_info.subtype}
                      onChange={({target: {value}}: any) => {
                        console.log(value);
                        setAddElementParams({
                          ...addElementParams,
                          css: {
                            ...addElementParams.css,
                            display_info: {
                              ...addElementParams.css.display_info,
                              subtype: value,
                            }
                          },
                        })
                      }}
                    >
                      <option value='default'>Default</option>
                      <option value='textarea'>Textarea</option>
                      <option value='e-mail'>E-mail</option>
                      <option value='phone'>Phone</option>
                      <option value='date'>Date</option>
                      <option value='gps-position'>get GPS position</option>
                    </select>
                  </label>
                </>
              )}
              {addElementParams.c_type === c_type.SELECT && (
                <>
                  <label className="d-block">
                    {i18n.translate('general.subtype')}
                    <select
                      className="form-control"
                      value={addElementParams.css.display_info.subtype}
                      onChange={({target: {value}}: any) => {
                        console.log(value);
                        setAddElementParams({
                          ...addElementParams,
                          css: {
                            ...addElementParams.css,
                            display_info: {
                              ...addElementParams.css.display_info,
                              subtype: value,
                            }
                          },
                        })
                      }}
                    >
                      <option value='default'>Default</option>
                      <option value='exclusive'>Exclusive</option>
                      <option value='multiple'>Multiple</option>
                    </select>
                  </label>
                </>
              )}
              {addElementParams.c_type === c_type.FILE && (
                <>
                  <label className="d-block">
                    {i18n.translate('general.subtype')}
                    <select
                      className="form-control"
                      value={addElementParams.css.display_info.subtype}
                      onChange={({target: {value}}: any) => {
                        console.log(value);
                        setAddElementParams({
                          ...addElementParams,
                          css: {
                            ...addElementParams.css,
                            display_info: {
                              ...addElementParams.css.display_info,
                              subtype: value,
                            }
                          },
                        })
                      }}
                    >
                      <option value='default'>Default</option>
                      <option value='read-code'>Read code</option>
                      <option value='take-photo'>Take photo</option>
                      <option value='file-image'>File image</option>
                      <option value='file-document'>File document</option>
                    </select>
                  </label>
                </>
              )}
            </div>
          )}

          {addElementParams.c_type === c_type.MEDIA && (
            <>
              <label className="d-block">
                {i18n.translate('general.subtype')}
                <select
                  className="form-control"
                  value={addElementParams.css.display_info.subtype}
                  onChange={({target: {value}}: any) => {
                    console.log(value);
                    setAddElementParams({
                      ...addElementParams,
                      css: {
                        ...addElementParams.css,
                        display_info: {
                          ...addElementParams.css.display_info,
                          subtype: value,
                        }
                      },
                    })
                  }}
                >
                  <option value='default'>Default</option>
                  <option value='background'>Background</option>
                  <option value='align-Rx'>Align Rx</option>
                  <option value='align-Lx'>Align Lx</option>
                </select>
              </label>
            </>
          )}

          {addElementParams.c_type === c_type.LINK && (
            <>
              <label className="d-block">
                {i18n.translate('general.subtype')}
                <select
                  className="form-control"
                  value={addElementParams.css.display_info.subtype}
                  onChange={({target: {value}}: any) => {
                    console.log(value);
                    setAddElementParams({
                      ...addElementParams,
                      css: {
                        ...addElementParams.css,
                        display_info: {
                          ...addElementParams.css.display_info,
                          subtype: value,
                        }
                      },
                    })
                  }}
                >
                  <option value='default'>Default</option>
                  <option value='button'>Button</option>
                  <option value='iframe'>iframe</option>
                </select>
              </label>
            </>
          )}

          {addElementParams.c_type === c_type.BUTTON && (
            <>
              <label className="d-block">
                {i18n.translate('general.subtype')}
                <select
                  className="form-control"
                  value={addElementParams.css.display_info.subtype}
                  onChange={({target: {value}}: any) => {
                    console.log(value);
                    setAddElementParams({
                      ...addElementParams,
                      css: {
                        ...addElementParams.css,
                        display_info: {
                          ...addElementParams.css.display_info,
                          subtype: value,
                        }
                      },
                    })
                  }}
                >
                  <option value='default'>Default</option>
                  <option value='skip-validation'>Skip validation</option>
                </select>
              </label>
            </>
          )}

          {addElementParams.c_type === c_type.SELECT && (
            <>
              <SelectArrayOptions languages={languages} workflow={workflow} addElementParams={addElementParams} setAddElementParams={setAddElementParams} />
            </>
          )}

          {addElementParams.c_type !== c_type.SELECT && (
            <div className="border border-dark my-2">
              <div className="d-flex">
                {languages.map((lang: string, idx: number) => (
                  <div
                    key={'side-wf-lang-' + idx}
                    className={'border px-1' + (lang === currentLang ? ' bg-dark text-white' : '')}
                    onClick={() => setCurrentLang(lang)}
                  >{lang}</div>
                ))}
              </div>

              {/* label */}
              {(
                addElementParams.c_type === c_type.MESSAGE
                || addElementParams.c_type === c_type.INPUT
                || addElementParams.c_type === c_type.BUTTON
                || addElementParams.c_type === c_type.TXT_ASSIGN
                // || addElementParams.c_type === c_type.SELECT
                || addElementParams.c_type === c_type.FILE
              ) && (
                <div>
                  <p>{i18n.translate('sidebar.label')}</p>
                  <textarea
                    className="w-100"
                    value={
                      getLang(addElementParams.langs, currentLang).label
                    }
                    onChange={({target: {value}}: any) => {
                      setAddElementParams({
                        ...addElementParams,
                        langs: {
                          ...addElementParams.langs,
                          [currentLang]: {
                            label: value,
                            uri: '',
                          }
                        }
                      })
                    }}
                  />
                </div>
              )}

              {/* uri */}
              {(
                addElementParams.c_type === c_type.MEDIA
                || addElementParams.c_type === c_type.LINK
              ) && (
                <div>
                  <p>{i18n.translate('sidebar.uri')}</p>
                  <input
                    className="w-100"
                    value={getLang(addElementParams.langs, currentLang).uri}
                    onChange={({target: {value}}: any) => {
                      setAddElementParams({
                        ...addElementParams,
                        langs: {
                          ...addElementParams.langs,
                          [currentLang]: {
                            ...addElementParams.langs[currentLang],
                            uri: value,
                          }
                        }
                      })
                    }}
                  />
                  <p>{i18n.translate('sidebar.label')}</p>
                  <input
                    className="w-100"
                    value={getLang(addElementParams.langs, currentLang).label}
                    onChange={({target: {value}}: any) => {
                      setAddElementParams({
                        ...addElementParams,
                        langs: {
                          ...addElementParams.langs,
                          [currentLang]: {
                            ...addElementParams.langs[currentLang],
                            label: value,
                          }
                        },
                      })
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {addElementParams.c_type === c_type.BUTTON && (
            <div>
              <p>{i18n.translate('general.exit')}</p>
              <select
                className="w-100 form-control"
                value={addElementParams.exit_num}
                onChange={({target: {value}}: any) => {
                  setAddElementParams({
                    ...addElementParams,
                    exit_num: value
                  })
                }}>
                  {block && block.exits.map((el, idx) => (
                    <option key={'side-new-elem-exit-' + idx} value={idx}>{idx}</option>
                  ))}
              </select>
            </div>
          )}

          <div>
            <p>{i18n.translate('general.css')}</p>
            <input
              className="w-100 text-monospace"
              type="text"
              value={addElementParams.css.class}
              onChange={({target: {value}}: any) => {
                setAddElementParams({
                  ...addElementParams,
                  css: {
                    ...addElementParams.css,
                    class: value
                  },
                })
              }}
            />
          </div>

          <div className="d-flex justify-content-around mt-4">
            <button
              className="btn btn-secondary"
              onClick={() => {onModalClose()}}
            >{i18n.translate('general.cancel')}</button>
            <button
              className="btn btn-primary"
              onClick={async () => {
                if (type === 'new') {
                  const elem_id = await Api.getUUID();
                  dispatch(WorkflowActions.updateLocalWorkflow({
                    ...workflow,
                    blocks: workflow.blocks.map((b: Block) => {
                      if (b.block_id == selectedBlockId) {
                        const elements = b.elements;
                        const data = {
                          ...addElementParams,
                          // var_name: addElementParams.var_name,
                          // c_type: addElementParams.c_type,
                          d_type: Object.keys(c_type)[Number(addElementParams.c_type)],
                          // css: addElementParams.css,
                          // exit: addElementParams.exit,
                          // langs: addElementParams.langs,
                        }

                        elements.push({
                          ...data,
                          elem_id,
                          e_order: String(
                            elements.reduce((prev, curr) => prev > Number(curr.e_order) ? prev : Number(curr.e_order), 0) + 1
                          ),
                          note: '',
                        })

                        return {
                          ...b,
                          elements
                        }
                      }
                      return b;
                    }),
                  }))
                } else {
                  dispatch(WorkflowActions.updateLocalWorkflow({
                    ...workflow,
                    blocks: workflow.blocks.map((b: Block) => {
                      if (b.block_id == selectedBlockId) {
                        const elements = b.elements;
                        const data = {
                          ...addElementParams,
                          // var_name: addElementParams.var_name,
                          // c_type: addElementParams.c_type,
                          d_type: Object.keys(c_type)[Number(addElementParams.c_type)],
                          // css: addElementParams.css,
                          // exit: addElementParams.exit,
                          // langs: addElementParams.langs,
                        }

                        return {
                          ...b,
                          elements: elements.map(el => {
                            if (el.elem_id === addElementParams.elem_id) {
                              return data
                            }
                            return el
                          })
                        }
                      }
                      return b;
                    }),
                  }))
                }
                onModalClose()
              }}
            >{i18n.translate('general.save')}</button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const SidebarHeading = () => {
  const name = '';
  const { list } = useSelector((state: any) => state.general);
  const dispatch = useDispatch();

  return (
    <div className="d-flex align-items-center">
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href={list}>
      {/* <Link className="sidebar-brand d-flex align-items-center justify-content-center" to=""> */}
        <div className="sidebar-brand-icon">
          <img src={Logo} alt={name} style={{height: '50px'}} />
        </div>
        <div className="sidebar-brand-text ml-2 h43">{name}</div>
      {/* </Link> */}
      </a>

      <div className="ml-auto mr-3">
        <button className="btn btn-light ml-3" title={i18n.translate('editor.settings')} onClick={() => {
          dispatch(WorkflowActions.selectBlock(undefined))
        }}>
          <i className="fas fa-cog"></i>
        </button>
        <button className="btn btn-light ml-3" title={i18n.translate('general.save')} onClick={() => {
          dispatch(WorkflowActions.updateWorkflowRequest())
        }}>
          <i className="fas fa-download"></i>
        </button>
      </div>
    </div>
  )
}

const SidebarComponent = (props: any) => {
  const { sidebar } = useSelector((state: any) => state.general);
  const { data, selectedBlockId, selectedWorkflowId }= useSelector((state: any) => state.workflows);
  const sidebarRef = useRef<any>(null);
  const workflow = useMemo(() => {
    return data[selectedWorkflowId] as Workflow
  }, [data, selectedWorkflowId]);
  const block = useMemo(() => {
    if (!workflow) return;
    return workflow.blocks.find(block => block.block_id === selectedBlockId);
  }, [selectedBlockId, workflow])

  const startBlock = useMemo(() => {
    if (!workflow) return;
    return workflow.blocks.find(block => block.block_id === workflow.start_block_id);
  }, [workflow])

  const { type } = sidebar;

  const onSidebarResize = useSidebarResize(sidebarRef);
  const [modalStatus, setModalStatus] = useState<false | 'new' | 'edit'>(false);
  const [modalElement, setModalElement] = useState<Element>();

  return (
    <>
      <ul ref={sidebarRef} className="position-relative navbar-nav bg-gradient-primary sidebar sidebar-dark accordion text-white d-flex justify-content-between" style={{width: '370px'}}>
        <div className="position-absolute d-flex flex-column justify-content-between" style={{left: 0, right: 0, top: 0, height: '100vh'}}>
    {/* display: flex;
    flex-direction: column;
    justify-content: space-between; */}
          <div>
            {type === 'menu' ? (
              <>
                <SidebarHeading />

                {/*  Divider --> */}
                <hr className="sidebar-divider my-0" />

                <li className="nav-item active">
                  {/* <Link className="nav-link" to=""> */}
                  <a href="https://flussu.com">
                    <i className="fas fa-fw fa-home"></i>
                    <span>Home</span>
                  </a>
                  {/* </Link> */}
                </li>
              </>
            ) : (
              <>
                {(workflow || block) && (
                  <>
                    {selectedBlockId === false || !block ? (
                      <WorkflowDefaultSidebar workflow={workflow} startBlock={startBlock} />
                    ) : (
                      <WorkflowSelectedBlockSidebar
                        workflow={workflow}
                        block={block}
                        addNewElement={() => {
                          setModalStatus('new');
                          setModalElement({
                            elem_id: '',
                            e_order: '',
                            d_type: '',
                            note: '',
                            var_name: '',
                            c_type: '0',
                            css: { class: '', display_info: {} },
                            exit_num: '0',
                            langs: workflow.supp_langs.split(',').reduce(
                              (prev: any, lang: string): Langs => ({
                                ...prev,
                                [lang]: {
                                  label: '',
                                  uri: '',
                                },
                              }), {}
                            ),
                          })
                        }}
                        editElement={(el: Element) => {
                          setModalStatus('edit');
                          setModalElement(el)
                        }}
                      />
                    )}
                  </>
                )}
                </>

              )}
          </div>

          <Credits />

          {modalStatus && (
            <ElementModal
              model={modalElement as Element}
              type={modalStatus as 'edit' | 'new'}
              languages={workflow.supp_langs.split(',')}
              block={block as Block}
              onModalClose={() => {
                setModalStatus(false);
              }}
              workflow={workflow}
              selectedBlockId={selectedBlockId}
            />
          )}
          <div className="position-sticky cursor-pointer bg-light border border-dark p-1 text-dark" style={{
            bottom: 0,
            width: '24px',
            left: '100%',
          }}
          onClick={onSidebarResize.onClick}
          >
            <i className="fas fa-arrow-left"></i>
          </div>
        </div>
      </ul>
      <div className="resize-handle--x" data-target="aside" onMouseDown={onSidebarResize.onMouseDown} />
    </>
  )
};

export default SidebarComponent;
